
import { defineComponent } from "vue";
export default defineComponent({
  emits: ["update:modelValue", "change"],
  name: "CheckBox",
  props: {
    modelValue: {
      type: Boolean,
    },
    name: {
      type: String,
    },
    description: {
      type: String,
      required: false,
    },
    colored: {
      type: Boolean,
    },
  },
  methods: {
    clicked() {
      this.$emit("update:modelValue", !this.modelValue);
      this.$emit("change", !this.modelValue);
    },
  },
});

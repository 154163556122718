
import CheckBox from "@/components/CheckBox.vue";

import electronBridge from "@/utils/electronBridge";

import { defineComponent } from "vue";
export default defineComponent({
  name: "StartupOptions",
  components: { CheckBox },
  data() {
    return {
      autoLaunch: false,
      launchMinimized: false,
    };
  },
  async mounted() {
    if (!this.$isElectron) return;
    this.autoLaunch = await electronBridge?.invoke(
      "get_store_value",
      "startup.enabled",
      true
    );
    this.launchMinimized = await electronBridge?.invoke(
      "get_store_value",
      "startup.minimized",
      true
    );
  },
  methods: {
    toggleStartup(state: boolean) {
      this.autoLaunch = state;
      electronBridge?.invoke("set_store_value", "startup.enabled", state);
    },
    toggleLaunchMinimized(state: boolean) {
      this.launchMinimized = state;
      electronBridge?.invoke("set_store_value", "startup.minimized", state);
    },
  },
});
